.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Toastify__toast-icon {
  margin-inline-end: unset !important;
}

.MuiPaginationItem-root {
  background-color: unset !important;
}

.MuiPaginationItem-previousNext {
  background: #ffffff !important;
  border: 1px solid #f2f2f4 !important;
  border-radius: 4px !important;
}

.MuiPaginationItem-root {
  font-family: 'BentonSans' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 15px !important;
  color: rgba(0, 0, 0, 0.29) !important;
  /* color: #3f5291 !important; */
}

.Mui-selected.MuiPaginationItem-root {
  color: #3f5291 !important;
}

.rdrNextPrevButton {
  display: none !important;
  width: 24px;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  border: 0;
  border-radius: 5px;
  background: #eff2f7;
}

.rdrMonthPicker {
  background: #3e669f !important;
  color: #ffffff !important;
}

.rdrMonthPicker select {
  color: #ffffff !important;
}

.rdrYearPicker {
  background: #3e669f !important;
  color: #ffffff !important;
}

.rdrYearPicker select {
  color: #ffffff !important;
}

.rdrSelected {
  border-radius: 0.04em !important;
  color: #3e669f !important;
}
.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span::after,
.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span::after,
.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span::after,
.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span::after {
  background: #3e669f !important;
}

.rdrSelected span::after {
  color: #3e669f !important;
}
.rdrDayNumber span::after {
  color: #3e669f !important;
}
.rdrDayToday,
.rdrDayNumber span::after {
  background: #ffffff !important;
}

.rdrCalendarWrapper {
  width: 15vw !important;
}

.rdrMonthAndYearWrapper {
  margin-right: 0px !important;
  padding: 10px 10px 10px 15px !important;
}

.rdrMonthPicker,
.rdrYearPicker {
  font-family: 'BentonSans' !important;
}
/* select alternating items starting with the second item */
.rdrWeekDays:nth-of-type(odd) {
  background: #f4f7fc !important;
}
.rdrWeekDays:nth-of-type(even) {
  background: #ffffff !important;
}

.Mui-error {
  font-family: 'BentonSans' !important;
}

.MuiTableRow-root > th {
  padding: 10px;
}

.MuiTableRow-root > td {
  padding: 10px;
}
